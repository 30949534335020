.settings-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  /* left: 0; */
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
}

.settings-sheet {
  background-color: white;
  padding: 20px;
  right: 0;
  border-radius: 10px;
  margin-right: 40%;
  color: black;
  width: 80%;
  max-width: 500px;
  box-sizing: border-box;
}

.settings-title {
  font-size: 40px; /* Larger title */
  text-align: center;
  margin-bottom: 20px;
}

.setting-control {
  margin: 20px 0;
}

.setting-title {
  margin-bottom: 5px;
  text-align: left; /* Align to left */
}

.settings-description {
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
  padding: 0 15px; /* Add padding to keep text within the sheet */
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px; /* Keep the slider track size */
  background: #75787B;
  outline: none;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.slider::before {
  content: '';
  position: absolute;
  height: 10px;
  left: 0;
  right: var(--right-position, 100%);
  background-color: #43B02A;
  border-radius: 5px;
  z-index: 1; /* Below the thumb */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0px; /* Increased size of the thumb */
  height: 30px; /* Increased size of the thumb */
  background: #43B02A;
  cursor: pointer;
  border-radius: 100%;
  position: relative;
  z-index: 2;
}

.slider::-moz-range-thumb {
  width: 30px; /* Increased size of the thumb */
  height: 30px; /* Increased size of the thumb */
  background: #43B02A;
  cursor: pointer;
  border-radius: 50%;
}

.slider-value {
  position: absolute;
  top: -28px;
  left: var(--thumb-position, 0); /* Variable for thumb position */
  transform: translateX(-50%);
}

.slider-scale {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.scale-mark {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.scale-number {
  margin-left: 5px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 20px; /* Increase gap between buttons */
  margin-top: 20px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: black;
}

.close-button {
  background-color: #DA291C;
}

.save-button {
  background-color: #43B02A;
}
