.tags-input-container{
border: .1px solid #B8B8B8;
padding: .5em;
border-radius: 5px;
margin-left: 5px;
width: 90%;
height: min(30vw, 200px);
/* margin-top: 1em; */
display: flex;
align-items: center;
flex-wrap: wrap;
gap: .5em;
}

.tag-item{
background-color: rgb(218, 216, 216);
display: inline-block;
padding: .5em .75em;
border-radius: 20px;
}
.tag-item .close{
height: 20px;
width: 20px;
background-color: rgb(48, 48, 48);
color: #fff;
border-radius: 50%;
display: inline-flex;
justify-content: center;
align-items: center;
margin-left: .5em;
font-size: 18px;
cursor: pointer;
}

.tags-input{
flex-grow: 1;
padding: .5em 0;
border: none;
outline: none;
font-size: 18px;
}